/** @jsx jsx */
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { Grid, jsx } from "theme-ui"

import Media from "@components/Media"
import RichText from "@components/RichText"
import Variant from "@components/Variant"

import List from "./List"
import Subtitle from "./Subtitle"
import Title from "./Title"

export const fragment = graphql`
  fragment ContentfulTestimonialBlock on ContentfulTestimonialBlock {
    backgroundcolor
    id
    layout
    links {
      href
      id
      isInternal
      slug
      title
    }
    media {
      ...Media
    }
    subtitle
    text {
      json
    }
    textStyle
    title
  }
`

export default function Testimonials({
  as,
  layout,
  links,
  media,
  subtitle,
  text,
  title,
  textStyle,
}) {
  const imageLeft = layout === "Image left"

  return (
    <Variant
      as={as}
      columns={["1fr", null, "1fr 1fr"]}
      d="grid"
      gap={[13, null, null, 16]}
      sx={{
        alignItems: "center",
        justifyItems: "center",

        "& + &": {
          pt: [0, null, 15],
        },
      }}
      variants={["container", "containerSpacing"]}
    >
      <div
        sx={{
          maxWidth: 500,
          order: [null, null, imageLeft ? "0" : "1"],
          width: "100%",
        }}
      >
        <Media media={media} />
      </div>
      <Grid
        as="div"
        gap={textStyle === "Black text" ? 10 : 7}
        sx={{ maxWidth: 500 }}
      >
        {title && subtitle ? (
          <Title>{title}</Title>
        ) : (
          <Subtitle textAlign={textStyle === "Black text" ? "left" : "center"}>
            {title}
          </Subtitle>
        )}
        <Subtitle>{subtitle}</Subtitle>
        <List links={links} />
        {text?.json ? (
          <RichText textStyle={textStyle} json={text.json} pv="large" />
        ) : null}
      </Grid>
    </Variant>
  )
}

Testimonials.typeName = "ContentfulTestimonialBlock"

Testimonials.defaultProps = {
  links: null,
  subtitle: undefined,
  text: null,
  title: undefined,
}

Testimonials.propTypes = {
  as: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
  links: PropTypes.array,
  media: PropTypes.object.isRequired,
  subtitle: PropTypes.string,
  text: PropTypes.shape({
    json: PropTypes.object,
  }),
  title: PropTypes.string,
}
