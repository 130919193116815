/** @jsx jsx */
import PropTypes from "prop-types"
import { jsx, Styled } from "theme-ui"

export default function Subtitle({ children }) {
  return (
    <Styled.h3
      sx={{
        textAlign: "center",

        "h2 + &": {
          marginBottom: [0, 7],
        },
      }}
    >
      {children}
    </Styled.h3>
  )
}

Subtitle.propTypes = {
  children: PropTypes.node.isRequired,
}
