/** @jsx jsx */
import PropTypes from "prop-types"
import { Flex, Grid, jsx, Styled } from "theme-ui"

import Image from "@components/Image"
import Variant from "@components/Variant"

export default function Link({ date, image, link, newspage, title }) {
  return (
    <Grid
      as="a"
      columns={["1fr", "100px 1fr 30px"]}
      gap={[6, 10]}
      href={link}
      rel="noreferrer noopener"
      sx={{
        alignItems: "center",
        justifyItems: ["center", "flex-start"],
        px: 11,
        py: 7,
        textDecoration: "none",

        ":hover": {
          "> img": {
            transform: "translateX(4px)",
          },
        },
      }}
      target="_blank"
    >
      <div sx={{ height: 100, width: 100 }}>
        <Image {...image} />
      </div>
      <Flex sx={{ flexDirection: "column" }}>
        <Styled.p sx={{ color: "text", mb: 3, textAlign: ["center", "left"] }}>
          {newspage} {date}
        </Styled.p>
        <Variant
          as="h4"
          sx={{ textAlign: ["center", "left"] }}
          variant="caption"
        >
          {title}
        </Variant>
      </Flex>
      <img
        alt="angle icon"
        src="/imgs/angle.svg"
        sx={{
          display: ["none", "inline-block"],
          transition: "transform 300ms ease",
        }}
      />
    </Grid>
  )
}

Link.propTypes = {
  date: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
  newspage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
