/** @jsx jsx */
import PropTypes from "prop-types"
import { Fragment } from "react"
import { jsx } from "theme-ui"

import Image from "@components/Image"
import Variant from "@components/Variant"

export default function ImageWithTitle({ image, title }) {
  return (
    <Fragment>
      <div
        sx={{
          width: "120px",
          gridRow: [null, 1],
          justifySelf: ["end", "center"],
        }}
      >
        <Image file={image.file} fluid={image.fluid} title={image.title} />
      </div>
      <Variant
        as="h4"
        sx={{
          justifySelf: ["start", "center"],
          m: 0,
          textAlign: "left",
        }}
        variant="caption"
      >
        {title}
      </Variant>
    </Fragment>
  )
}

ImageWithTitle.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
}
