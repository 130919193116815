/** @jsx jsx */
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { jsx } from "theme-ui"

const styles = {
  variant: "button.primary",
  justifySelf: "center",
  marginTop: [8, 11],
  textDecoration: "none",
}

export default function CtaLink({ children, href, to }) {
  if (href) {
    return (
      <a href={href} sx={styles}>
        {children}
      </a>
    )
  }

  if (to) {
    return (
      <Link sx={styles} to={to}>
        {children}
      </Link>
    )
  }

  return null
}

CtaLink.defaultProps = {
  href: undefined,
  to: undefined,
}

CtaLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  to: PropTypes.string,
}
