/** @jsx jsx */
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { Grid, jsx } from "theme-ui"

import Variant from "@components/Variant"

import List from "./List"
import Map from "./Map"

export const fragment = graphql`
  fragment ContentfulContactBlock on ContentfulContactBlock {
    content {
      id
      links {
        href
        id
        slug
        title
      }
      subtitle
      text {
        json
      }
      title
    }
    id
    map {
      map
    }
  }
`

export default function Contact({ content, map }) {
  return (
    <Variant
      as="section"
      columns={["1fr", null, "1fr 1fr"]}
      d="grid"
      gap={[12, 15]}
      variants={["container", "containerSpacing"]}
    >
      <Grid as="div" gap={12}>
        <List list={content} />
      </Grid>
      <Map map={map} />
    </Variant>
  )
}

Contact.typeName = "ContentfulContactBlock"

Contact.propTypes = {
  content: PropTypes.array.isRequired,
  map: PropTypes.shape({
    map: PropTypes.string.isRequired,
  }).isRequired,
}
