/** @jsx jsx */
import PropTypes from "prop-types"
import { Grid, jsx } from "theme-ui"

import Link from "./Link"

export default function Links({ links }) {
  if (Array.isArray(links) && links.length) {
    return (
      <Grid as="div" gap={7} sx={{ mt: 7 }}>
        {links.map(link => (
          <Link href={link.href} key={link.id} slug={link.slug}>
            {link.title}
          </Link>
        ))}
      </Grid>
    )
  }

  return null
}

Links.defaultProps = {
  links: null,
}

Links.propTypes = {
  links: PropTypes.array,
}
