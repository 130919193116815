/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby"
import { useMemo } from "react"
import { jsx } from "theme-ui"

import TrustBox from "@components/TrustBox"
import useLocaleContext from "@hooks/useLocaleContext"

export default function Trustpilot() {
  const locale = useLocaleContext()

  const data = useStaticQuery(
    graphql`
      {
        trustpilotblocks: allContentfulTrustpilotBlock {
          nodes {
            configuration {
              businessunitId
              carouselTemplateId
              reviewLanguages
              tags
              targetUrl
            }
            node_locale
          }
        }
      }
    `
  )

  const { configuration } = useMemo(
    () =>
      data.trustpilotblocks.nodes.find(block => block.node_locale === locale),
    [data, locale]
  )

  return (
    <div sx={{ gridColumn: [null, null, "span 2"], mt: "44px" }}>
      <TrustBox
        businessunitId={configuration.businessunitId}
        locale={locale}
        reviewLanguages={configuration.reviewLanguages}
        tags={configuration.tags}
        targetUrl={configuration.targetUrl}
        templateId={configuration.carouselTemplateId}
      />
    </div>
  )
}
