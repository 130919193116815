/** @jsx jsx */
import PropTypes from "prop-types"
import { jsx } from "theme-ui"

export default function ListTitle({ children }) {
  return <h4 sx={{ variant: "caption" }}>{children}</h4>
}

ListTitle.propTypes = {
  children: PropTypes.node.isRequired,
}
