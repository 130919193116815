/** @jsx jsx */
import { Link as GatsbyLink } from "gatsby"
import PropTypes from "prop-types"
import { jsx } from "theme-ui"

export default function Link({ children, href, slug }) {
  if (slug) {
    return (
      <GatsbyLink
        sx={{
          variant: "button.secondary",
        }}
        to={slug}
      >
        {children}
      </GatsbyLink>
    )
  }

  if (href) {
    return (
      <a
        href={href}
        rel="noreferrer noopener"
        sx={{
          variant: "button.secondary",
        }}
        target="_blank"
      >
        {children}
      </a>
    )
  }

  return null
}

Link.defaultProps = {
  children: null,
  href: undefined,
  slug: undefined,
}

Link.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  slug: PropTypes.string,
}
