/** @jsx jsx */
import { Fragment, useEffect, useState } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { jsx, Styled } from "theme-ui"

import Variant from "@components/Variant"
import Border from "@src/components/Border"
import useLocaleContext from "@src/hooks/useLocaleContext"

import Loading from "./Loading/Loading"
import { formatDate, formatNumber } from "./helper"

export const fragment = graphql`
  fragment ContentfulDepositTermsBlock on ContentfulDepositTermsBlock {
    id
    title
    depositTermsUrl
  }
`

export default function DepositTerms({ title, depositTermsUrl }) {
  const locale = useLocaleContext()

  const [termsData, setTermsData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const currentDate = formatDate(new Date())

  const currentTerms =
    termsData.length > 0 ? (
      <table>
        <thead>
          <tr>
            <td>Bindningstid</td>
            <td>Aktuell ränta</td>
          </tr>
        </thead>
        <tbody>
          {termsData?.map(item => {
            const termName =
              item.term_period < 12
                ? `${item.term_period} mån`
                : `${item.term_period / 12} år`
            return (
              <tr key={item.name}>
                <td>{item.type === "flex" ? "Rörlig" : termName}</td>
                <td>
                  {formatNumber(locale, item.interest_rate, {
                    style: "percent",
                  })}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    ) : (
      <p sx={{ fontSize: 3, textAlign: "center" }}>
        Just nu verkar våra aktuella räntor och bindningstider inte kunna visas,
        ladda om sidan eller försök igen senare.
      </p>
    )

  useEffect(() => {
    setIsLoading(true)
    fetch(depositTermsUrl)
      .then(response => response.json())
      .then(({ data }) => {
        const sortedData = data.sort((a, b) => {
          if (a.term_period === null) return -1
          if (b.term_period === null) return 1
          return a.term_period - b.term_period
        })
        setTermsData(sortedData)
        setIsLoading(false)
      })
      .catch(() => setIsLoading(false))
  }, [])

  return (
    <Fragment>
      <Variant
        variants={["container", "containerSpacing"]}
        d="grid"
        as="section"
        gap={0}
        sx={{
          flexDirection: "column",
          alignItems: "flex-start",
          textAlign: "left",
          fontSize: 3,
          lineHeight: "1.25",
          p: {
            margin: 0,
            "&:empty": {
              display: "none",
            },
          },
          table: {
            borderCollapse: "collapse",
            width: "100%",
            marginBottom: [5],
          },
          thead: {
            td: {
              borderBottom: "2px solid",
              borderColor: "grey.light",
              fontWeight: 400,
              padding: [5],
            },
          },
          td: {
            padding: ["20px 16px", "36px 16px"],
          },
          tr: {
            "&:nth-of-type(2n+2)": {
              backgroundColor: "rgba(245, 245, 245, 1)",
            },
          },
        }}
      >
        <Styled.h2 sx={{ textAlign: "center", marginBottom: [9, 13] }}>
          {title}
        </Styled.h2>
        {isLoading ? (
          <Variant
            variants={["container", "containerSpacing"]}
            d="flex"
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            <Loading />
          </Variant>
        ) : (
          <Variant>
            {currentTerms}
            {termsData.length > 0 && (
              <Variant
                as="p"
                sx={{
                  textAlign: "left",
                  width: "100%",
                  fontSize: 3,
                }}
              >
                Aktuella räntor {currentDate}
              </Variant>
            )}
          </Variant>
        )}
      </Variant>
      <Border />
    </Fragment>
  )
}

DepositTerms.typeName = "ContentfulDepositTermsBlock"

DepositTerms.defaultProps = {
  title: "",
  depositTermsUrl: "",
}

DepositTerms.propTypes = {
  title: PropTypes.string.isRequired,
  depositTermsUrl: PropTypes.string.isRequired,
}
