/** @jsx jsx */
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { jsx } from "theme-ui"

import Variant from "@components/Variant"

import Sections from "./Sections"

export const fragment = graphql`
  fragment ContentfulDocument2ColumnBlock on ContentfulDocument2ColumnBlock {
    documentSections {
      documents {
        document {
          file {
            url
          }
          title
        }
        id
        title
      }
      id
      text {
        text
      }
    }
    id
  }
`

export default function DocumentsTwoColumn({ documentSections }) {
  return (
    <Variant
      as="section"
      columns={["1fr", "1fr 1fr"]}
      d="grid"
      gap={12}
      variants={["container", "containerSpacing"]}
    >
      <Sections sections={documentSections} />
    </Variant>
  )
}

DocumentsTwoColumn.typeName = "ContentfulDocument2ColumnBlock"

DocumentsTwoColumn.propTypes = {
  documentSections: PropTypes.array.isRequired,
}
