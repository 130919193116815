/** @jsx jsx */
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { Fragment } from "react"
import { jsx, Styled } from "theme-ui"

import Border from "@components/Border"
import Variant from "@components/Variant"

import StaffList from "./StaffList"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

export const fragment = graphql`
  fragment ContentfulBoardManagementBlock on ContentfulBoardManagementBlock {
    id
    title
    intro {
      json
    }
    staff {
      __typename
      ... on ContentfulBoardBlock {
        id
        title
        board {
          id
          name
          birthYear
          hired
          info {
            info
          }
          role
          image {
            ...FluidImageFields
            ...ImageFields
          }
          imageEnhancement
        }
      }
      ... on ContentfulManagementBlock {
        id
        title
        managementStaff {
          id
          name
          birthYear
          hired
          info {
            info
          }
          role
          image {
            ...FluidImageFields
            ...ImageFields
          }
          imageEnhancement
        }
      }
    }
  }
`

export default function BoardAndManagement({ staff, title, intro }) {
  return (
    <Fragment>
      <div id={title.toLowerCase()}></div>
      <Variant
        as="section"
        d="flex"
        sx={{
          alignItems: "center",
          flexDirection: "column",
          maxWidth: "930px",
        }}
        variants={["container", "containerSpacing"]}
      >
        <Styled.h2 sx={{ mb: [5, 13], textAlign: "center" }}>{title}</Styled.h2>

        <p
          sx={{
            variant: "richtext.p",
            fontSize: "20px",
            paddingBottom: "120px",
          }}
        >
          {intro?.json && documentToReactComponents(intro.json)}
        </p>
        {Array.isArray(staff)
          ? staff.map(item => (
              <StaffList
                key={item.id}
                list={item.managementStaff || item.board}
              />
            ))
          : null}
      </Variant>
      <Border />
    </Fragment>
  )
}

BoardAndManagement.typeName = "ContentfulBoardManagementBlock"

BoardAndManagement.propTypes = {
  staff: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
}
