/** @jsx jsx */
import PropTypes from "prop-types"
import { jsx, Styled } from "theme-ui"

import Link from "./Link"

export default function List({ links }) {
  if (Array.isArray(links) && links.length) {
    return (
      <Styled.ul sx={{ my: 5 }}>
        {links.map(link => (
          <li key={link.id} sx={{ "& + &": { mt: 6 } }}>
            <Link {...link}>{link.title}</Link>
          </li>
        ))}
      </Styled.ul>
    )
  }

  return null
}

List.defaultProps = {
  links: null,
}

List.propTypes = {
  links: PropTypes.array,
}
