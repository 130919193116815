/** @jsx jsx */
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { Fragment } from "react"
import { jsx } from "theme-ui"

import Border from "@components/Border"
import Variant from "@components/Variant"

import Description from "./Description"
import List from "./List"

export const fragment = graphql`
  fragment ContentfulHowItWorksBlock on ContentfulHowItWorksBlock {
    id
    illustrations {
      id
      image {
        fluid(maxWidth: 120) {
          ...GatsbyContentfulFluid
        }
        ...ImageFields
      }
      title
    }

    text {
      text
    }
    title
  }
`

export default function HowItWorks({ illustrations, text: { text }, title }) {
  return (
    <Fragment>
      <Variant
        as="section"
        sx={{ textAlign: "center" }}
        variants={["container", "containerSpacing"]}
      >
        <Description text={text} title={title} />
        <List illustrations={illustrations} />
      </Variant>
      <Border />
    </Fragment>
  )
}

HowItWorks.typeName = "ContentfulHowItWorksBlock"

HowItWorks.propTypes = {
  illustrations: PropTypes.array.isRequired,
  text: PropTypes.shape({ text: PropTypes.string }).isRequired,
  title: PropTypes.string.isRequired,
}
