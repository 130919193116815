import Testimonials from "@components/blocks/Testimonials"
import PropTypes from "prop-types"
import React from "react"

function getComponentByTypeName(typeName, props) {
  switch (typeName) {
    case Testimonials.typeName:
      return <Testimonials as="section" key={props.id} {...props} />
    default:
      console.warn(`No matching component found for type ${typeName}`)
      break
  }
}

function BlockMapper({ blocks }) {
  return blocks
    ? blocks.map(({ __typename, ...rest }) =>
        getComponentByTypeName(__typename, rest)
      )
    : null
}

BlockMapper.propTypes = {
  blocks: PropTypes.array,
}

export default BlockMapper
