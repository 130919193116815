/** @jsx jsx */
import PropTypes from "prop-types"
import { Fragment } from "react"
import { Flex, jsx, Styled } from "theme-ui"

import Border from "@components/Border"
import Variant from "@components/Variant"

import BroccForm from "./BroccForm"
import Illustration from "./Illustration"
import List from "./List"
import SpeechBubble from "./SpeechBubble"
import Trustpilot from "./Trustpilot"
import RichText from "@src/components/RichText"

export default function LoanForm({
  backgroundcolor,
  formWidget,
  formlist,
  image,
  showTrustpilot,
  speechBubble,
  title,
  text,
}) {
  function handleFormComplete(data) {
    if (data?.uid && typeof window !== "undefined") {
      window.location.replace(
        `${process.env.GATSBY_BROCC_APP_URL}/onboarding/${data.uid}`
      )
    }
  }

  return (
    <Fragment>
      <Variant
        as="section"
        d="grid"
        gap={[15, null, 12]}
        id="loan"
        sx={{
          columnGap: 15,
          gridTemplateColumns: "1fr",
          px: 0,
          py: 15,
          rowGap: 15,

          "@media screen and (min-width: 768px)": {
            columnGap: 10,
            px: 6,
            py: 18,
          },

          "@media screen and (min-width: 992px)": {
            columnGap: 12,
            gridTemplateColumns: "auto minmax(670px, 1fr)",
          },
        }}
        variant="container"
      >
        <Flex
          sx={{
            alignItems: ["center", null, "flex-start"],
            flexDirection: "column",
            mt: [null, 12],
            px: [6, 0],
          }}
        >
          <Styled.h2 sx={{ mb: 7, textAlign: ["center", null, "left"] }}>
            {title}
          </Styled.h2>
          <SpeechBubble speechBubble={speechBubble} />
          {text && <RichText json={text.json} pv="medium" />}
          <List list={formlist} loan />
          <Illustration image={image} loan />
        </Flex>
        <BroccForm
          bgc={backgroundcolor}
          loan
          widget={formWidget}
          messageHandler={handleFormComplete}
        />
        {showTrustpilot ? <Trustpilot /> : null}
      </Variant>
      <Border />
    </Fragment>
  )
}

LoanForm.defaultProps = {
  formlist: null,
  image: null,
  speechBubble: null,
}

LoanForm.propTypes = {
  backgroundcolor: PropTypes.string.isRequired,
  formWidget: PropTypes.string.isRequired,
  formlist: PropTypes.array,
  image: PropTypes.object,
  showTrustpilot: PropTypes.bool.isRequired,
  speechBubble: PropTypes.object,
  title: PropTypes.string.isRequired,
}
