/** @jsx jsx */
import React from "react"
import Person from "./Person"
import { Flex, jsx } from "theme-ui"
const StaffList = ({ list }) => {
  return (
    <div
      sx={{
        width: "100%",
      }}
    >
      {list.map(person => {
        return <Person data={person} />
      })}
    </div>
  )
}

export default StaffList
