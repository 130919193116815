/** @jsx jsx */
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { jsx } from "theme-ui"

import GenericForm from "./GenericForm"
import LoanForm from "./LoanForm"

export const fragment = graphql`
  fragment ContentfulFormBlock on ContentfulFormBlock {
    backgroundcolor
    formWidget
    text {
      json
    }
    formlist: list {
      id
      text
    }
    id
    image {
      fluid(maxWidth: 250, quality: 80) {
        ...GatsbyContentfulFluid
      }
      ...ImageFields
    }
    showTrustpilot
    speechBubble {
      json
    }
    subtitle
    title
  }
`

function handleDepositFormCompletion(data) {
  if (data?.uid && typeof window !== "undefined") {
    window.location.replace(
      `${process.env.GATSBY_BROCC_APP_URL}/login?redirect=/savings/onboarding/${data.uid}`
    )
  }
}

export default function Form(props) {
  switch (props.formWidget) {
    case "loan-application":
      return <LoanForm {...props} />
    case "investment-application":
      return <GenericForm {...props} id="investment" />
    case "fixed-deposit-application":
      return (
        <GenericForm
          {...props}
          id="fixed-deposit"
          messageHandler={handleDepositFormCompletion}
        />
      )
    case "flex-deposit-application":
      return (
        <GenericForm
          {...props}
          id="flex-deposit"
          messageHandler={handleDepositFormCompletion}
        />
      )
    default:
      return null
  }
}

Form.typeName = "ContentfulFormBlock"

Form.propTypes = {
  formWidget: PropTypes.string.isRequired,
}
