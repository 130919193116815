/** @jsx jsx */
import PropTypes from "prop-types"
import { Flex, jsx } from "theme-ui"

import Plus from "@components/Plus"

function Question({ children, active }) {
  return (
    <Flex
      sx={{
        fontSize: 3,
        fontWeight: "bold",
        py: [8, 7],
        px: [0, 8],
        overflow: "hidden",
        alignItems: "center",
      }}
    >
      <span sx={{ flex: 1 }}>{children}</span>
      <Plus active={active} />
    </Flex>
  )
}

Question.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}

export default Question
