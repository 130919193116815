/** @jsx jsx */
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { Fragment } from "react"
import { jsx } from "theme-ui"

import Border from "@components/Border"
import Variant from "@components/Variant"

import List from "./List"

export const fragment = graphql`
  fragment ContentfulDocumentBlock on ContentfulDocumentBlock {
    id

    documents {
      id
      title
      document {
        file {
          url
        }
      }
    }
    heading2
    text {
      text
    }
  }
`

export default function Documents({ documents, text, heading2 }) {
  return (
    <Fragment>
      <div
        id={
          heading2 &&
          heading2
            .toLowerCase()
            .replace(/ /g, "-")
            .replace(/å/g, "a")
            .replace(/ä/g, "a")
            .replace(/ö/g, "o")
        }
      ></div>
      <Variant
        as="section"
        d="flex"
        sx={{
          flexDirection: "column",
          justifyContent: "center",
        }}
        variants={["container", "containerSpacing"]}
      >
        {heading2 && (
          <h1
            sx={{
              textAlign: "center",
              fontSize: "30px",
              "@media(min-width: 900px)": {
                fontSize: "55px",
              },
            }}
          >
            {heading2}
          </h1>
        )}
        {text?.text ? (
          <p sx={{ variant: "text.preamble", alignSelf: "center", pb: 13 }}>
            {text?.text}
          </p>
        ) : null}
        <List documents={documents} />
      </Variant>
      <Border />
    </Fragment>
  )
}

Documents.typeName = "ContentfulDocumentBlock"

Documents.defaultProps = {
  documents: null,
  text: null,
}

Documents.propTypes = {
  documents: PropTypes.array,
  heading: PropTypes.string,
  text: PropTypes.shape({
    text: PropTypes.string,
  }),
}
