/** @jsx jsx */
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { Grid, jsx, Styled } from "theme-ui"

import Card from "@components/Newspage/Card"
import Category from "@components/Newspage/Category"

export default function Posts({ posts }) {
  if (Array.isArray(posts) && posts.length) {
    return (
      <Grid as="div" columns={["1fr", "1fr 1fr"]} gap={[6, 10, 15]} sx={{}}>
        {posts.map(post => (
          <Link key={post.id} sx={{ variant: "link" }} to={post.slug}>
            <Card>
              <Category categories={post.categories} />
              <Styled.h3 sx={{ mb: 5 }}>{post.postTitle}</Styled.h3>
              <p sx={{ variant: "text.large", color: "grey.dark" }}>
                {post.excerpt}
              </p>
            </Card>
          </Link>
        ))}
      </Grid>
    )
  }

  return null
}

Posts.defaultProps = {
  posts: null,
}

Posts.propTypes = {
  posts: PropTypes.array,
}
