/** @jsx jsx */
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { jsx } from "theme-ui"

import ContentBlockMapper from "@components/ContentBlockMapper"

function Page({ page }) {
  const { contentBlocks } = page
  return <ContentBlockMapper blocks={contentBlocks} />
}

Page.propTypes = {
  page: PropTypes.shape().isRequired,
}

export const fragment = graphql`
  fragment Page on ContentfulPage {
    contentBlocks {
      __typename
      ...ContentfulBoardManagementBlock
      ...ContentfulContactBlock
      ...ContentfulDocumentBlock
      ...ContentfulDocument2ColumnBlock
      ...ContentfulFaqBlock
      ...ContentfulFeatureBlock
      ...ContentfulFormBlock
      ...ContentfulHeroBlock
      ...ContentfulHowItWorksBlock
      ...ContentfulListingBlock
      ...ContentfulMediaBlock
      ...ContentfulNewsBlock
      ...ContentfulSimpleContactBlock
      ...ContentfulSimpleHeroBlock
      ...ContentfulTestimonialBlock
      ...ContentfulTextTemplateBlock
      ...ContentfulTrustpilotBlock
      ...ContentfulWelcomeBlock
      ...ContentfulDepositTermsBlock
    }
    contentful_id
    id
    slug
    title
  }
`

export default Page
