/** @jsx jsx */
import { jsx } from "theme-ui"
import "./Loading.css"

const Loading = () => (
  <svg viewBox="0 0 50 50" class="loading" width={50} height={50}>
    <circle
      stroke="black"
      class="path"
      cx="25"
      cy="25"
      r="20"
      fill="transparent"
      strokeLinecap="round"
      stroke-width="4"
    ></circle>
  </svg>
)

export default Loading
