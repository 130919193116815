/** @jsx jsx */
import PropTypes from "prop-types"
import { Grid, jsx, Styled } from "theme-ui"

export default function ListItem({ icon, text, title }) {
  return (
    <Grid
      columns={["1fr", null, "40px 1fr"]}
      sx={{
        columnGap: 5,
        justifyItems: ["center", null, "flex-start"],
        rowGap: [4, null, 1],
        img: {
          gridRow: "span 2",
        },
      }}
    >
      <img
        alt={icon?.altText}
        src={icon?.file?.url}
        sx={{ height: "40px", width: "40px" }}
      />
      <h4 sx={{ variant: "caption" }}>{title}</h4>
      <Styled.p sx={{ textAlign: ["center", null, "left"] }}>
        {text.text}
      </Styled.p>
    </Grid>
  )
}

ListItem.propTypes = {
  icon: PropTypes.shape({
    altText: PropTypes.string,
    file: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  text: PropTypes.shape({
    text: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
}
