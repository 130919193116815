/** @jsx jsx */
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { Fragment } from "react"
import { jsx } from "theme-ui"

import Border from "@components/Border"
import Media from "@components/Media"
import Variant from "@components/Variant"

import List from "./List"

export const fragment = graphql`
  fragment ContentfulFeatureBlock on ContentfulFeatureBlock {
    id
    layout
    link {
      href
      slug
      title
    }
    list {
      icon {
        altText: title
        file {
          url
        }
      }
      id
      text {
        text
      }
      title
    }
    listTitle
    media {
      ...Media
    }
    subtitle
    text {
      json
    }
    title
  }
`

export default function Features({
  layout,
  link,
  list,
  listTitle,
  media,
  subtitle,
  title,
  text,
}) {
  const imgLeft = layout === "Image left"
  return (
    <Fragment>
      <Variant
        as="section"
        columns={["1fr", null, "1fr 1fr"]}
        d="grid"
        gap={[0, null, 6]}
        sx={{
          rowGap: [6, 13, 0],
          "> .image-container": {
            justifySelf: ["center", null, imgLeft ? "flex-start" : "flex-end"],
            order: [null, null, imgLeft ? "0" : "1"],
          },
        }}
        variants={["container", "containerSpacing"]}
      >
        <div
          className="image-container"
          sx={{
            alignSelf: "center",
            height: "100%",
            marginBottom: [12, 0],
            maxWidth: [media?.json ? 200 : 250, 375],
            width: "100%",
          }}
        >
          <Media media={media} />
        </div>
        <List
          link={link}
          list={list}
          listTitle={listTitle}
          subtitle={subtitle}
          text={text}
          title={title}
        />
      </Variant>
      <Border />
    </Fragment>
  )
}

Features.typeName = "ContentfulFeatureBlock"

Features.defaultProps = {
  link: null,
  listTitle: undefined,
  subtitle: undefined,
  text: null,
}

Features.propTypes = {
  layout: PropTypes.string.isRequired,
  link: PropTypes.shape({
    href: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string.isRequired,
  }),
  list: PropTypes.array.isRequired,
  listTitle: PropTypes.string,
  media: PropTypes.object.isRequired,
  subtitle: PropTypes.string,
  text: PropTypes.shape({
    json: PropTypes.object.isRequired,
  }),
  title: PropTypes.string.isRequired,
}
