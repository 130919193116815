/** @jsx jsx */
import PropTypes from "prop-types"
import { Fragment } from "react"
import { Flex, jsx, Styled } from "theme-ui"

import Border from "@components/Border"
import Variant from "@components/Variant"

import BroccForm from "./BroccForm"
import Illustration from "./Illustration"
import List from "./List"

export default function GenericForm({
  messageHandler,
  backgroundcolor,
  formWidget,
  formlist,
  image,
  subtitle,
  title,
  id,
}) {
  return (
    <Fragment>
      <section id={id} sx={{ backgroundColor: `rgba(${backgroundcolor})` }}>
        <Variant
          as="article"
          columns={["1fr", "1fr 1fr"]}
          d="grid"
          sx={{
            columnGap: [15],
            rowGap: [8, 13],
            py: [15, 18],
          }}
          variant="container"
        >
          <Styled.h2
            sx={{
              gridColumn: "1 / -1",
              justifySelf: "center",
              textAlign: "center",
            }}
          >
            {title}
          </Styled.h2>
          <Flex
            sx={{
              flexDirection: "column",
              mt: [null, 12],
              order: [5, "unset"],
              px: 6,
            }}
          >
            <Styled.h3 sx={{ maxWidth: 350, mb: 7 }}>{subtitle}</Styled.h3>
            <List list={formlist} />
            <Illustration image={image} />
          </Flex>
          <BroccForm
            bgc={backgroundcolor}
            widget={formWidget}
            messageHandler={messageHandler}
          />
        </Variant>
      </section>
      <Border />
    </Fragment>
  )
}

GenericForm.defaultProps = {
  formlist: null,
  image: null,
}

GenericForm.propTypes = {
  messageHandler: PropTypes.func,
  backgroundcolor: PropTypes.string.isRequired,
  formWidget: PropTypes.string.isRequired,
  formlist: PropTypes.array,
  image: PropTypes.object,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}
