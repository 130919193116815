/** @jsx jsx */
import RichText from "@src/components/RichText"
import PropTypes from "prop-types"
import { jsx } from "theme-ui"

export default function SpeechBubble({ speechBubble }) {
  if (speechBubble?.json) {
    return (
      <div
        sx={{
          backgroundColor: "black",
          borderRadius: ["0 32px 32px 32px", null, "0 20px 20px 20px"],
          maxWidth: 400,
          mb: 10,
          px: 8,
          py: 6,

          "> p": {
            color: "white",
            "> strong, > b": {
              color: "white",
            },
          },
        }}
      >
        <RichText json={speechBubble.json} />
      </div>
    )
  }

  return null
}

SpeechBubble.defaultProps = {
  speechBubble: null,
}

SpeechBubble.propTypes = {
  speechBubble: PropTypes.object,
}
