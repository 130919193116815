/** @jsx jsx */
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { Fragment } from "react"
import { jsx } from "theme-ui"

import Border from "@components/Border"

import BlockMapper from "./BlockMapper"

export const fragment = graphql`
  fragment ContentfulListingBlock on ContentfulListingBlock {
    id
    blocks {
      __typename
      ...ContentfulTestimonialBlock
    }
  }
`

export default function Listing({ blocks }) {
  if (Array.isArray(blocks) && blocks.length) {
    return (
      <Fragment>
        <BlockMapper blocks={blocks} />
        <Border />
      </Fragment>
    )
  }

  return null
}

Listing.typeName = "ContentfulListingBlock"

Listing.propTypes = {
  blocks: PropTypes.array.isRequired,
}
