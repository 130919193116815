/** @jsx jsx */
import PropTypes from "prop-types"
import { Fragment } from "react"
import { jsx, Styled } from "theme-ui"

export default function Description({ text, title }) {
  return (
    <Fragment>
      <h2 sx={{ variant: "caption" }}>{title}</h2>
      <Styled.h3 sx={{ mt: 7, mb: 12 }}>{text}</Styled.h3>
    </Fragment>
  )
}

Description.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
