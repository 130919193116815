/** @jsx jsx */
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { Fragment } from "react"
import { jsx, Styled } from "theme-ui"

import Border from "@components/Border"
import Image from "@components/Image"
import Variant from "@components/Variant"

export const fragment = graphql`
  fragment ContentfulWelcomeBlock on ContentfulWelcomeBlock {
    id
    image {
      ...FluidImageFields
      ...ImageFields
    }
    text {
      text
    }
    title
  }
`

export default function Welcome({
  image: { file, fluid, title: imageTitle },
  text: { text },
  title,
}) {
  return (
    <Fragment>
      <Variant
        as="section"
        d="flex"
        sx={{
          alignItems: "center",
          flexDirection: "column",
        }}
        variants={["container", "containerSpacing"]}
      >
        <h2 sx={{ variant: "caption" }}>{title}</h2>
        <Styled.h3
          sx={{
            marginBottom: 13,
            marginTop: 7,
            maxWidth: 900,
            textAlign: "center",
          }}
        >
          {text}
        </Styled.h3>
        <div sx={{ width: "100%", maxWidth: "440px" }}>
          <Image file={file} fluid={fluid} title={imageTitle} />
        </div>
      </Variant>
      <Border />
    </Fragment>
  )
}

Welcome.typeName = "ContentfulWelcomeBlock"

Welcome.propTypes = {
  image: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string.isRequired,
      details: PropTypes.shape({
        image: PropTypes.shape({
          width: PropTypes.number.isRequired,
          height: PropTypes.number.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
    fluid: PropTypes.object,
    title: PropTypes.string.isRequired,
  }).isRequired,
  text: PropTypes.shape({
    text: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
}
