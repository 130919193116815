/** @jsx jsx */
import { graphql } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"
import PropTypes from "prop-types"
import { Fragment, useMemo, useState } from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion"
import { Flex, jsx, Styled } from "theme-ui"

import Border from "@components/Border"
import Variant from "@components/Variant"

import Answer from "./Answer"
import Question from "./Question"

const SHOW_ENUMS = {
  INITIAL: "initial",
  MAXIMUM: "maximum",
}

export const fragment = graphql`
  fragment ContentfulFaqBlock on ContentfulFaqBlock {
    id
    initiallyVisible
    maximumVisible
    questions {
      id
      question
      glossary {
        id
        json
      }
      answer {
        id
        answer
      }
    }
    showAllTitle
    showAllLink
    showLessTitle
    showMoreTitle
    title
  }
`

function Faq({
  initiallyVisible,
  maximumVisible,
  questions,
  showAllTitle,
  showAllLink,
  showLessTitle,
  showMoreTitle,
  title,
  glossary,
}) {
  const showButton = questions.length > initiallyVisible
  const [showEnum, setShowEnum] = useState(SHOW_ENUMS.INITIAL)
  const visibleQuestions = useMemo(
    () =>
      questions.slice(
        0,
        showEnum === SHOW_ENUMS.INITIAL ? initiallyVisible : maximumVisible
      ),
    [initiallyVisible, questions, showEnum, maximumVisible]
  )

  const showMax = () => setShowEnum(SHOW_ENUMS.MAXIMUM)
  const showInitial = () => {
    scrollTo(`#${title.toLowerCase().replace(/ /g, "-")}`)
    setShowEnum(SHOW_ENUMS.INITIAL)
  }

  return (
    <Fragment>
      <div id={title.toLowerCase().replace(/ /g, "-")}></div>
      <Variant
        as="section"
        d="flex"
        sx={{
          alignItems: "center",
          flexDirection: "column",
        }}
        variants={["container", "containerSpacing"]}
      >
        <Styled.h2 sx={{ mb: 13, textAlign: "center" }}>{title}</Styled.h2>

        {visibleQuestions && visibleQuestions.length ? (
          <Accordion
            allowMultipleExpanded
            allowZeroExpanded
            sx={{
              borderBottom: "1px solid",
              borderColor: "disabled",
              width: "100%",
            }}
          >
            {visibleQuestions.map(
              ({ question, answer: { answer, id }, glossary }) => (
                <AccordionItem
                  key={id}
                  sx={{ borderTop: "1px solid", borderColor: "disabled" }}
                >
                  <AccordionItemHeading>
                    <AccordionItemButton
                      sx={{ cursor: "pointer", outline: "transparent" }}
                    >
                      <AccordionItemState>
                        {({ expanded }) => (
                          <Question active={expanded}>{question}</Question>
                        )}
                      </AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <AccordionItemState>
                      {({ expanded }) => (
                        <Answer glossary={glossary} active={expanded}>
                          {answer}
                        </Answer>
                      )}
                    </AccordionItemState>
                  </AccordionItemPanel>
                </AccordionItem>
              )
            )}
          </Accordion>
        ) : null}
        {showButton && showEnum === SHOW_ENUMS.INITIAL ? (
          <Flex sx={{ justifyContent: "center", mt: 12 }}>
            <button onClick={showMax} sx={{ variant: "button.secondary" }}>
              {showMoreTitle}
            </button>
          </Flex>
        ) : null}
        {showButton &&
        showLessTitle &&
        showEnum === SHOW_ENUMS.MAXIMUM ? (
          <Flex sx={{ justifyContent: "center", mt: 12 }}>
            <button onClick={showInitial} sx={{ variant: "button.secondary" }}>
              {showLessTitle}
            </button>
          </Flex>
        ) : null}
        {showButton &&
        !showLessTitle &&
        showEnum === SHOW_ENUMS.MAXIMUM ? (
          <Flex sx={{ justifyContent: "center", mt: 12 }}>
            <a href={showAllLink} sx={{ variant: "button.secondary" }}>
              {showAllTitle}
            </a>
          </Flex>
        ) : null}
      </Variant>
      <Border />
    </Fragment>
  )
}

Faq.typeName = "ContentfulFaqBlock"

Faq.propTypes = {
  initiallyVisible: PropTypes.number.isRequired,
  maximumVisible: PropTypes.number.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape(
      {
        title: PropTypes.string.isRequired,
        answer: PropTypes.shape({
          answer: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired,
        }).isRequired,
      }.isRequired
    )
  ).isRequired,
  showAllLink: PropTypes.string,
  showAllTitle: PropTypes.string,
  showLessTitle: PropTypes.string,
  showMoreTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default Faq
