/** @jsx jsx */
import PropTypes from "prop-types"
import { Flex, jsx, Styled } from "theme-ui"

export default function List({ documents }) {
  if (Array.isArray(documents) && documents.length) {
    return (
      <Styled.ul>
        {documents.map(document => (
          <Flex
            as="li"
            key={document.id}
            sx={{
              alignItems: "center",
              borderBottom: "2px solid",
              borderBottomColor: "grey.light",
              justifyContent: "space-between",
              px: [6, 13],
              py: [5, 8],

              ":first-of-type": {
                borderTop: "2px solid",
                borderTopColor: "grey.light",
              },
            }}
          >
            {/* eslint-disable */}
            <a
              href={document?.document?.file?.url}
              rel="external noopener noreferrer"
              sx={{
                variant: "link",
                color: "text",
                fontSize: [1, 3],
                fontWeight: "bold",
              }}
              target="_blank"
            >
              {document.title}
            </a>
            <a
              href={document?.document?.file?.url}
              rel="external noopener noreferrer"
              target="_blank"
            >
              {/* eslint-enable */}
              <img
                alt="download icon"
                src="/imgs/download.svg"
                sx={{
                  height: [25, 40],
                  width: [25, 40],
                }}
              />
            </a>
          </Flex>
        ))}
      </Styled.ul>
    )
  }

  return null
}

List.defaultProps = {
  documents: null,
}

List.propTypes = {
  documents: PropTypes.array,
}
