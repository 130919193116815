/** @jsx jsx */
import PropTypes from "prop-types"
import { jsx, Styled } from "theme-ui"

import Link from "./Link"

export default function List({ links }) {
  if (Array.isArray(links) && links.length) {
    return (
      <Styled.ul sx={{ mt: 13, width: "100%" }}>
        {links.map(link => (
          <Styled.li
            key={link.id}
            sx={{
              borderBottom: "2px solid",
              borderBottomColor: "grey.light",

              ":first-of-type": {
                borderTop: "2px solid",
                borderTopColor: "grey.light",
              },
            }}
          >
            <Link {...link} />
          </Styled.li>
        ))}
      </Styled.ul>
    )
  }

  return null
}

List.defaultProps = {
  links: null,
}

List.propTypes = {
  links: PropTypes.array,
}
