/** @jsx jsx */

import PropTypes from "prop-types"
import { jsx } from "theme-ui"

import Line from "./Line"

function Plus({ active, size }) {
  return (
    <div
      aria-hidden="true"
      sx={{
        width: size,
        height: size,
        position: "relative",
        transition: "transform 0.3s ease-out",
        transform: `rotate(${active ? "90deg" : "0"})`,
      }}
    >
      <Line rotated />
      <Line hide={active} rotated={active} />
    </div>
  )
}

Plus.defaultProps = {
  active: false,
  size: ["24px", "28px"],
}

Plus.propTypes = {
  active: PropTypes.bool,
  size: PropTypes.arrayOf(PropTypes.string),
}

export default Plus
