/** @jsx jsx */

import PropTypes from "prop-types"
import { jsx } from "theme-ui"

const Line = ({ rotated, hide }) => (
  <div
    sx={{
      height: "4px",
      bg: "black",
      borderRadius: "8px",
      position: "absolute",
      top: "50%",
      transform: `translateY(-2px) rotate(${rotated ? "90deg" : "0"})`,
      opacity: hide ? "0" : "1",
      transition: "all 0.3s ease-out",

      left: 0,
      right: 0,
    }}
  />
)

Line.defaultProps = {
  hide: false,
  rotated: false,
}

Line.propTypes = {
  hide: PropTypes.bool,
  rotated: PropTypes.bool,
}

export default Line
