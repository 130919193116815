/** Components */
import BoardAndManagement from "@components/blocks/BoardAndManagement"
import Contact from "@components/blocks/Contact"
import Documents from "@components/blocks/Documents"
import DocumentsTwoColumn from "@components/blocks/DocumentsTwoColumn"
import Faq from "@components/blocks/Faq"
import Feature from "@components/blocks/Feature"
import Form from "@components/blocks/Form"
import HeroBlock from "@components/blocks/HeroBlock"
import HowItWorks from "@components/blocks/HowItWorks"
import Listing from "@components/blocks/Listing"
import Media from "@components/blocks/Media"
import News from "@components/blocks/News"
import SimpleContact from "@components/blocks/SimpleContact"
import SimpleHero from "@components/blocks/SimpleHero"
import TestimonialsWithBorder from "@components/blocks/TestimonialsWithBorder"
import TextTemplate from "@components/blocks/TextTemplate"
import Trustpilot from "@components/blocks/Trustpilot"
import Welcome from "@components/blocks/Welcome"
import DepositTerms from "@components/blocks/DepositTerms"
import PropTypes from "prop-types"
import React from "react"

function getComponentByTypeName(typeName, props) {
  switch (typeName) {
    case BoardAndManagement.typeName:
      return <BoardAndManagement key={props.id} {...props} />
    case Contact.typeName:
      return <Contact key={props.id} {...props} />
    case Documents.typeName:
      return <Documents key={props.id} {...props} />
    case DocumentsTwoColumn.typeName:
      return <DocumentsTwoColumn key={props.id} {...props} />
    case Faq.typeName:
      return <Faq key={props.id} {...props} />
    case Feature.typeName:
      return <Feature key={props.id} {...props} />
    case Form.typeName:
      return <Form key={props.id} {...props} />
    case HeroBlock.typeName:
      return <HeroBlock key={props.id} {...props} />
    case Listing.typeName:
      return <Listing key={props.id} {...props} />
    case Media.typeName:
      return <Media key={props.id} {...props} />
    case News.typeName:
      return <News key={props.id} {...props} />
    case SimpleContact.typeName:
      return <SimpleContact key={props.id} {...props} />
    case SimpleHero.typeName:
      return <SimpleHero key={props.id} {...props} />
    case TestimonialsWithBorder.typeName:
      return <TestimonialsWithBorder key={props.id} {...props} />
    case TextTemplate.typeName:
      return <TextTemplate key={props.id} {...props} />
    case Trustpilot.typeName:
      return <Trustpilot key={props.id} {...props} />
    case Welcome.typeName:
      return <Welcome key={props.id} {...props} />
    case HowItWorks.typeName:
      return <HowItWorks key={props.id} {...props} />
    case DepositTerms.typeName:
      return <DepositTerms key={props.id} {...props} />
    default:
      console.warn(`No matching component found for type ${typeName}`)
      break
  }
}

function ContentBlockMapper({ blocks }) {
  return blocks
    ? blocks.map(({ __typename, ...rest }) =>
        getComponentByTypeName(__typename, rest)
      )
    : null
}

ContentBlockMapper.propTypes = {
  blocks: PropTypes.array,
}

export default ContentBlockMapper
