/** @jsx jsx */

import PropTypes from "prop-types"
import { Grid, jsx } from "theme-ui"

import ImageWithTitle from "./ImageWithTitle"

export default function List({ illustrations }) {
  if (Array.isArray(illustrations) && illustrations.length) {
    return (
      <Grid
        columns={["1fr 1fr", "repeat(3, auto)"]}
        gap={[5, 8]}
        sx={{ alignItems: "center", justifyContent: [null, "space-between"] }}
      >
        {illustrations.map(({ id, image, title }) => (
          <ImageWithTitle image={image} key={id} title={title} />
        ))}
      </Grid>
    )
  }
  return null
}

List.propTypes = {
  illustrations: PropTypes.array.isRequired,
}
