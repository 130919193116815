/** @jsx jsx */
import PropTypes from "prop-types"
import { Flex, Grid, jsx } from "theme-ui"

export default function List({ list, loan }) {
  if (Array.isArray(list) && list.length) {
    return (
      <Grid
        as="ul"
        sx={{
          listStyle: "none",
          pl: 0,
        }}
      >
        {list.map(item => (
          <Flex
            as="li"
            key={item.id}
            sx={{
              alignItems: "center",
              fontSize: loan ? [2, 3] : [4, 5],
              fontWeight: "bold",
              position: "relative",
              py: 4,
            }}
          >
            <img
              alt="Brocc logo yellow"
              src={loan ? "/imgs/brocc-yellow.svg" : "/imgs/brocc.svg"}
              sx={{
                height: "20px",
                mb: "4px",
                mr: 4,
                width: "20px",
              }}
            />
            <span sx={{ display: "inline-block", lineHeight: "1.2" }}>
              {item.text}
            </span>
          </Flex>
        ))}
      </Grid>
    )
  }

  return null
}

List.defaultProps = {
  list: null,
  loan: false,
}

List.propTypes = {
  list: PropTypes.array,
  loan: PropTypes.bool,
}
