/** @jsx jsx */
import RichText from "@src/components/RichText"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { Fragment } from "react"
import { jsx, Styled } from "theme-ui"

import Border from "@components/Border"
import Variant from "@components/Variant"
import Title from "@src/components/Hero/Title"

export const fragment = graphql`
  fragment ContentfulTextTemplateBlock on ContentfulTextTemplateBlock {
    image {
      file {
        url
      }
    }
    jumpLinkId
    preamble {
      json
    }
    centeredHeading
    text {
      json
    }
    id
  }
`

export default function TextTemplate({
  preamble,
  text,
  centeredHeading,
  image,
  jumpLinkId,
}) {
  return (
    <Fragment>
      {jumpLinkId && (
        <div
          id={jumpLinkId
            .toLowerCase()
            .replace(/ /g, "-")
            .replace(/å/g, "a")
            .replace(/ä/g, "a")
            .replace(/ö/g, "o")}
        ></div>
      )}
      <Variant
        as="section"
        sx={{ maxWidth: 850, mx: "auto" }}
        variants={["container", "containerSpacing"]}
      >
        {centeredHeading && (
          <h1
            sx={{
              textAlign: "center",
              p: { variant: "reset" },
              fontSize: "30px",
              span: { lineHeight: "1.3", whiteSpace: "nowrap" },
              "@media(min-width: 900px)": {
                fontSize: "55px",
              },
            }}
          >
            {centeredHeading}
          </h1>
        )}
        {image?.file?.url && (
          <img
            sx={{
              height: "300px",
              width: "100%",
              objectFit: "cover",
              borderRadius: "20px",
              mb: 10,
            }}
            src={image?.file?.url}
          />
        )}
        {preamble && (
          <div sx={{ mb: 12 }}>
            <RichText json={preamble.json} pv="preamble" />
          </div>
        )}
        <div
          sx={{
            "h1, h2, h3, h4, h5, h6": { textAlign: "left", mt: 12 },
            p: { my: 5 },
          }}
        >
          <RichText json={text.json} />
        </div>
      </Variant>
      <Border />
    </Fragment>
  )
}

TextTemplate.typeName = "ContentfulTextTemplateBlock"

TextTemplate.defaultProps = {
  preamble: undefined,
}

TextTemplate.propTypes = {
  preamble: PropTypes.shape({ json: PropTypes.object.isRequired }),
  text: PropTypes.shape({ json: PropTypes.object.isRequired }).isRequired,
}
