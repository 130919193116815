/** @jsx jsx */

import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { Fragment } from "react"
import { jsx, Styled } from "theme-ui"

import Border from "@components/Border"
import TrustBox from "@components/TrustBox"
import Variant from "@components/Variant"

export const fragment = graphql`
  fragment ContentfulTrustpilotBlock on ContentfulTrustpilotBlock {
    id
    title
    node_locale
    configuration {
      businessunitId
      tags
      reviewLanguages
      carouselTemplateId
      targetUrl
    }
    description {
      description
    }
  }
`

function Trustpilot({
  title,
  node_locale,
  configuration: {
    businessunitId,
    carouselTemplateId,
    targetUrl,
    reviewLanguages,
    tags,
  },
  description: { description },
}) {
  return (
    <Fragment>
      <Variant
        as="section"
        d="flex"
        sx={{
          alignItems: "center",
          flexDirection: "column",
        }}
        variants={["container", "containerSpacing"]}
      >
        <Styled.h2 sx={{ mb: 15, textAlign: "center" }}>{title}</Styled.h2>
        <div sx={{ width: "100%" }}>
          <TrustBox
            businessunitId={businessunitId}
            locale={node_locale}
            reviewLanguages={reviewLanguages}
            tags={tags}
            targetUrl={targetUrl}
            templateId={carouselTemplateId}
          />

          <p
            sx={{
              lineHeight: "1.4",
              color: "grey.dark",
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            {description}
          </p>
        </div>
      </Variant>
      <Border />
    </Fragment>
  )
}

Trustpilot.typeName = "ContentfulTrustpilotBlock"

Trustpilot.propTypes = {
  configuration: PropTypes.shape({
    businessunitId: PropTypes.string.isRequired,
    reviewLanguages: PropTypes.string.isRequired,
    tags: PropTypes.string.isRequired,
    targetUrl: PropTypes.string.isRequired,
    carouselTemplateId: PropTypes.string.isRequired,
  }).isRequired,
  node_locale: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  desription: PropTypes.shape({
    description: PropTypes.string.isRequired,
  }),
}

export default Trustpilot
