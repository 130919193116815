/** @jsx jsx */
import { Link as GatsbyLink } from "gatsby"
import PropTypes from "prop-types"
import { jsx } from "theme-ui"

const style = {
  variant: "link",
  color: "primary",
  fontSize: [1, 4],
  fontWeight: "bold",
  textDecoration: "underline",
}

export default function Link({ children, href, slug }) {
  if (slug) {
    return (
      <GatsbyLink sx={style} to={slug}>
        {children}
      </GatsbyLink>
    )
  }

  if (href) {
    return (
      <a href={href} rel="noreferrer noopener" sx={style} target="_blank">
        {children}
      </a>
    )
  }

  return null
}

Link.defaultProps = {
  href: undefined,
  slug: undefined,
}

Link.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  slug: PropTypes.string,
}
