export const formatNumber = (locale, value, options) => {
  const numberFormat = new Intl.NumberFormat(locale, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    currency: "SEK",
    roundingMode: "floor",
    ...options,
  })
  return numberFormat.format(value)
}

export const formatDate = date => {
  let year = date.getFullYear()
  let month = String(date.getMonth() + 1).padStart(2, "0")
  let day = String(date.getDate()).padStart(2, "0")
  let formattedDate = `${year}-${month}-${day}`

  return formattedDate
}
