/** @jsx jsx */
import PropTypes from "prop-types"
import { Children } from "react"
import { jsx, Styled } from "theme-ui"

export default function Subtitle({ children }) {
  if (Children.count(children)) {
    return <Styled.h4 sx={{ mb: 5 }}>{children}</Styled.h4>
  }

  return null
}

Subtitle.defaultProps = {
  children: null,
}

Subtitle.propTypes = {
  children: PropTypes.node,
}
