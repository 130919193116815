/** @jsx jsx */
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { jsx, Styled } from "theme-ui"

import Variant from "@components/Variant"

import Link from "./Link"
import Posts from "./Posts"

export const fragment = graphql`
  fragment ContentfulNewsBlock on ContentfulNewsBlock {
    id
    link {
      href
      slug
      title
    }
    posts {
      categories
      excerpt
      id
      postTitle
      slug
    }
    title
  }
`

export default function News({ link, posts, title }) {
  return (
    <Variant
      as="section"
      d="grid"
      gap={[10, 12, 15]}
      sx={{ justifyItems: "center" }}
      variants={["container", "containerSpacing"]}
    >
      <Styled.h2 sx={{ textAlign: "center" }}>{title}</Styled.h2>
      <Posts posts={posts} />
      <Link href={link.href} slug={link.slug}>
        {link.title}
      </Link>
    </Variant>
  )
}

News.typeName = "ContentfulNewsBlock"

News.defaultProps = {
  posts: null,
}

News.propTypes = {
  link: PropTypes.object.isRequired,
  posts: PropTypes.array,
  title: PropTypes.string.isRequired,
}
