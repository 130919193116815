/** @jsx jsx */
import PropTypes from "prop-types"
import { jsx } from "theme-ui"

export default function Map({ map }) {
  return (
    <div
      sx={{
        mt: ["40px", "70px"],
        pl: [7, 0],
        pr: [7, 11, null, 0],
        position: "relative",

        "::after": {
          content: '""',
          backgroundImage: `url(/imgs/foursparklestopright.svg)`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: ["55px", "105px"],
          position: "absolute",
          top: ["-40px", "-70px"],
          right: ["0px", null, null, "-60px"],
          width: ["40px", "80px"],
        },
      }}
    >
      <div
        dangerouslySetInnerHTML={{ __html: map?.map }}
        sx={{
          iframe: {
            borderRadius: "10px",
            width: "100%",
          },
        }}
      />
    </div>
  )
}

Map.propTypes = {
  map: PropTypes.shape({
    map: PropTypes.string.isRequired,
  }).isRequired,
}
