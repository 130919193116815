import { graphql } from "gatsby"
import PropTypes from "prop-types"
import React, { Fragment } from "react"

import Page from "@components/Page"
import Seo from "@components/Seo"

export default function PageTemplate({ data }) {
  return (
    <Fragment>
      {/* <Seo seo={page.seotags} /> */}
      <Page page={data.contentfulPage} />
    </Fragment>
  )
}

export const query = graphql`
  query ContentfulPageQuery($node_id: String!) {
    contentfulPage(id: { eq: $node_id }) {
      ...Page
    }
  }
`

PageTemplate.propTypes = {
  data: PropTypes.shape().isRequired,
}
