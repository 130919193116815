/** @jsx jsx */
import PropTypes from "prop-types"
import { Fragment } from "react"
import { jsx } from "theme-ui"

import Border from "@components/Border"

import Testimonials from "./Testimonials"

export default function TestimonialsWithBorder(props) {
  return (
    <Fragment>
      <section sx={{ backgroundColor: `rgba(${props.backgroundcolor})` }}>
        <Testimonials as="article" {...props} />
      </section>
      <Border />
    </Fragment>
  )
}

TestimonialsWithBorder.typeName = "ContentfulTestimonialBlock"

TestimonialsWithBorder.propTypes = {
  backgroundcolor: PropTypes.string.isRequired,
}
