/** @jsx jsx */
import { Link as GatsbyLink } from "gatsby"
import PropTypes from "prop-types"
import { jsx } from "theme-ui"

const style = {
  variant: "link",
  color: "primary",
  fontSize: 3,
  fontWeight: "bold",
}

export default function Link({ children, href, isInternal, slug }) {
  if (isInternal) {
    return (
      <GatsbyLink sx={style} to={slug}>
        {children}
      </GatsbyLink>
    )
  }

  return (
    <a href={href} rel="noreferrer noopener" sx={style} target="_blank">
      {children}
    </a>
  )
}

Link.defaultProps = {
  href: undefined,
  slug: undefined,
}

Link.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  isInternal: PropTypes.bool.isRequired,
  slug: PropTypes.string,
}
