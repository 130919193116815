/** @jsx jsx */
import { jsx } from "theme-ui"

import RichText from "@components/RichText"

import Links from "./Links"
import Subtitle from "./Subtitle"
import Title from "./Title"

export default function List({ list }) {
  if (Array.isArray(list) && list.length) {
    return list.map(item => (
      <article key={item.id}>
        <Title>{item.title}</Title>
        <Subtitle>{item.subtitle}</Subtitle>
        <RichText json={item.text.json} pv="large" />
        <Links links={item.links} />
      </article>
    ))
  }

  return null
}
