/** @jsx jsx */
import RichText from "@src/components/RichText"
import PropTypes from "prop-types"
import { jsx, Styled } from "theme-ui"

function Answer({ children, glossary }) {
  return (
    <Styled.p
      sx={{
        fontSize: 3,
        pt: [5, 3],
        pb: 12,
        px: [0, 8],
        opacity: "1",
        maxWidth: "768px",
        transition: "opacity 0.2s ease-in-out",
        animation: `fadeIn 0.2s ease-in-out`,
        "@keyframes fadeIn": {
          "0%": {
            opacity: 0,
          },
          "100%": {
            opacity: 1,
          },
        },
      }}
    >
      {!glossary ? children : <RichText json={glossary.json} />}
    </Styled.p>
  )
}

Answer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Answer
