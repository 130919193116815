/** @jsx jsx */
import PropTypes from "prop-types"
import { Grid, jsx } from "theme-ui"

import RichText from "@components/RichText"

import CtaLink from "./CtaLink"
import ListItem from "./ListItem"
import ListTitle from "./ListTitle"
import Subtitle from "./Subtitle"

export default function List({ link, list, listTitle, subtitle, text, title }) {
  return (
    <Grid
      as="article"
      gap={8}
      sx={{ alignContent: "center", minWidth: [null, null, 450] }}
    >
      {subtitle && title ? (
        <h2 sx={{ variant: "caption", textAlign: "center" }}>{title}</h2>
      ) : (
        <Subtitle>{title}</Subtitle>
      )}
      {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
      {text?.json ? (
        <div sx={{ mb: [null, 7] }}>
          <RichText center json={text.json} />
        </div>
      ) : null}
      {listTitle ? <ListTitle>{listTitle}</ListTitle> : null}
      <Grid gap={"32px"}>
        {list.map(listItem => (
          <ListItem key={listItem.id} {...listItem} />
        ))}
      </Grid>
      {link ? (
        <CtaLink href={link.href} to={link.slug}>
          {link.title}
        </CtaLink>
      ) : null}
    </Grid>
  )
}

List.defaultProps = {
  link: null,
  listTitle: undefined,
  subtitle: undefined,
  text: null,
}

List.propTypes = {
  link: PropTypes.shape({
    href: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string.isRequired,
  }),
  list: PropTypes.arrayOf(PropTypes.shape(ListItem.propTypes)).isRequired,
  listTitle: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.shape({
    json: PropTypes.object.isRequired,
  }),
  title: PropTypes.string.isRequired,
}
