/** @jsx jsx */
import locations from "@constants/locations"
import PropTypes from "prop-types"
import { useMemo } from "react"
import { Flex, jsx } from "theme-ui"
import useLocaleContext from "@hooks/useLocaleContext"

import Image from "@components/Image"

const styles = {
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  content: '""',
  display: "inline-block",
  position: "absolute",
}

const Person = ({ data }) => {
  const { name, birthYear, hired, role, image } = data
  const info = data?.info?.info
  const locale = useLocaleContext()

  const mapBirthYearTranslation = {
    "fi": "Syntynyt",
    "fi-FI": "Född",
    "sv-SE": "Född",
    "sv-EN": "Birth year",
  }
  const mapEmployedTranslation = {
    "fi": "Broccilla vuodesta",
    "fi-FI": "Anställd",
    "sv-SE": "Anställd",
    "sv-EN": "Employed",
  }

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "35px",
        "@media(min-width: 900px)": {
          maxHeight: "220px",
          flexDirection: "row",
        },
      }}
    >
      <div
        sx={{
          width: "100%",
          height: "250px",
          minHeight: "216px",
          borderRadius: "20px",
          overflow: "hidden",
          "@media(min-width: 768px)": {
            width: "216px",
            height: "216px",
            minWidth: "216px",
            minHeight: "216px",
          },
        }}
      >
        <Image file={image?.file} fluid={image?.fluid} title={image?.title} />
      </div>
      <div sx={{ padding: "0px 0px 0px 20px" }}>
        <p
          sx={{
            fontSize: "20px",
            fontWeight: "700",
            fontFamily: "body",
            lineHeight: "1.4",
          }}
        >
          {name}, {role}
        </p>
        <p>
          <span sx={{ marginRight: "16px" }}>{`${mapBirthYearTranslation[locale]}: ${birthYear}`}</span>
          <span>{hired ? ` ${mapEmployedTranslation[locale]}: ${hired}` : null}</span>
        </p>
        <p sx={{ lineHeight: "1.4", color: "grey.dark", fontSize: "20px" }}>
          {info}
        </p>
      </div>
    </div>
  )
}
export default Person

Person.defaultProps = {
  imageEnhancement: undefined,
  length: 0,
}

Person.propTypes = {
  hasImageEnhancements: PropTypes.bool.isRequired,
  imageEnhancement: PropTypes.string,
  image: PropTypes.object.isRequired,
  isManagement: PropTypes.bool.isRequired,
  length: PropTypes.number,
  name: PropTypes.string.isRequired,
  nthOfType: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
}
