/** @jsx jsx */
import PropTypes from "prop-types"
import { jsx } from "theme-ui"

import Image from "@components/Image"

export default function Illustration({ image, loan }) {
  if (image) {
    return (
      <div
        sx={{
          alignSelf: ["center", "initial"],
          display: [loan ? "none" : "block", "block"],
          maxWidth: 250,
          mt: 10,
          width: "100%",
        }}
      >
        {image ? <Image {...image} /> : null}
      </div>
    )
  }

  return null
}

Illustration.defaultProps = {
  image: null,
  loan: false,
}

Illustration.propTypes = {
  image: PropTypes.object,
  loan: PropTypes.bool,
}
