/** @jsx jsx */
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { jsx, Styled } from "theme-ui"

import Variant from "@components/Variant"

import List from "./List"

export const fragment = graphql`
  fragment ContentfulMediaBlock on ContentfulMediaBlock {
    id
    title
    links {
      id
      date
      image {
        ...FluidImageFields
        ...ImageFields
      }
      link
      newspage
      title
    }
  }
`

export default function Media({ links, title }) {
  return (
    <Variant
      as="section"
      d="flex"
      sx={{
        alignItems: "center",
        flexDirection: "column",
      }}
      variants={["container", "containerSpacing"]}
    >
      <div id={title.toLowerCase().replace(/ /g, "-")}></div>
      <Styled.h2>{title}</Styled.h2>
      <List links={links} />
    </Variant>
  )
}

Media.typeName = "ContentfulMediaBlock"

Media.defaultProps = {
  links: null,
}

Media.propTypes = {
  links: PropTypes.array,
  title: PropTypes.string.isRequired,
}
