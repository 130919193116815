/** @jsx jsx */
import PropTypes from "prop-types"
import { Children } from "react"
import { jsx, Styled } from "theme-ui"

export default function Subtitle({ children, textAlign }) {
  const align = textAlign ? textAlign : "center"
  const extraMargin = textAlign === "left" ? "20px" : "0px"

  if (Children.count(children)) {
    return (
      <Styled.h3 as="h2" sx={{ textAlign: align, marginBottom: extraMargin }}>
        {children}
      </Styled.h3>
    )
  }
  return null
}

Subtitle.defaultProps = {
  children: null,
}

Subtitle.propTypes = {
  children: PropTypes.node,
}
