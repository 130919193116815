/** @jsx jsx */
import PropTypes from "prop-types"
import { Children } from "react"
import { jsx, Styled } from "theme-ui"

export default function Title({ children }) {
  if (Children.count(children)) {
    return (
      <Styled.h3 sx={{ variant: "caption", textAlign: "center" }}>
        {children}
      </Styled.h3>
    )
  }
  return null
}

Title.defaultProps = {
  children: null,
}

Title.propTypes = {
  children: PropTypes.node,
}
