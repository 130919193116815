/**@jsx jsx */
import PropTypes from "prop-types"
import { useEffect, useRef } from "react"
import { jsx } from "theme-ui"

function TrustBox({
  businessunitId,
  templateId,
  targetUrl,
  locale,
  tags,
  height,
  reviewLanguages,
}) {
  const ref = useRef(null)

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])

  return (
    <div
      className="trustpilot-widget"
      data-businessunit-id={businessunitId}
      data-locale={locale}
      data-review-languages={reviewLanguages}
      data-style-height={height ? height : undefined}
      data-style-width="100%"
      data-tags={tags}
      data-template-id={templateId}
      ref={ref}
    >
      <a
        href={targetUrl}
        rel="noopener noreferrer"
        sx={{ color: "white" }}
        target="_blank"
      >
        Brocc on Trustpilot
      </a>
    </div>
  )
}

TrustBox.defaultProps = {
  height: null,
}

TrustBox.propTypes = {
  businessunitId: PropTypes.string.isRequired,
  height: PropTypes.string,
  locale: PropTypes.string.isRequired,
  reviewLanguages: PropTypes.string.isRequired,
  tags: PropTypes.string.isRequired,
  targetUrl: PropTypes.string.isRequired,
  templateId: PropTypes.string.isRequired,
}

export default TrustBox
