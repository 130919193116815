/** @jsx jsx */
import PropTypes from "prop-types"
import { jsx, Styled } from "theme-ui"

export default function Title({ children }) {
  return <Styled.h3 sx={{ mb: 5 }}>{children}</Styled.h3>
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
}
