/** @jsx jsx */
import PropTypes from "prop-types"
import { jsx, Styled } from "theme-ui"

export default function Sections({ sections }) {
  if (Array.isArray(sections) && sections.length) {
    return sections.map(section => (
      <article key={section.id}>
        <Styled.h3>{section.text?.text}</Styled.h3>
        <Styled.ul sx={{ mt: 8 }}>
          {section.documents.map(({ document, id, title }) => {
            const url = document?.file?.url
            return (
              <Styled.li key={id} sx={{ "& + &": { mt: 5 } }}>
                <a
                  href={url}
                  sx={{
                    color: "primary",
                    fontSize: [1, 3],
                    fontWeight: "bold",
                  }}
                >
                  {title}
                </a>
              </Styled.li>
            )
          })}
        </Styled.ul>
      </article>
    ))
  }

  return null
}

Sections.defaultProps = {
  sections: null,
}

Sections.propTypes = {
  sections: PropTypes.array,
}
